<script setup lang="ts">
  import { usePageStore } from '~/stores/page';

  const pageStore = usePageStore()

  const confirm = () => {
    pageStore.isForceNav = true
    navigateTo(pageStore.tempNavTo)
  }

  const cancel = () => {
      pageStore.isForceNav = false
      pageStore.isWarningShown = false
      pageStore.tempNavTo = null
  }
</script>

<template>
  <v-dialog v-model="pageStore.isWarningShown" max-width="360px"
    @keydown.esc="cancel" persistent>
    <v-card>
      <v-toolbar color="warning" density="compact">
        <v-toolbar-title>Unsaved Changes</v-toolbar-title>
      </v-toolbar>
      <v-card-text>There are unsaved changes.<br>Are you sure that you want to discard<br>all changes?</v-card-text>
      <v-card-actions class="pt-0">
        <v-spacer />
        <v-btn color="warning" variant="text" @click="confirm">Discard</v-btn>
        <v-btn color="brand-grey" variant="outlined" @click="cancel">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
